.foto{
    width: 220px;
    height: 220px;
}
.naam{
    color: white;
}
.functie{
    color: #008000;
    padding: 0px;
    margin: 0rem;
    border: 0px;
}
.upper{
    align-items: center;
    display: flex;
    flex-direction: column;
}



.down {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 10px;
    padding-left: 0px;
    margin-left: 0;
    

}

.vraag{
    color: #620573;
    font-size: large;
    text-align: center;
}

.vraagantwoord{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70px;
}

.antwoord{
    color: white;
    text-align: center;
}