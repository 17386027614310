.popup{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: scroll;
    background-color: rgba(0, 0, 0, 0.812);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    color: black;
    overflow: scroll;
    @media (min-height: 768px) {
        height: 100%;
        overflow: scroll;
      }
      
}

 .popup-inner{
    position: relative;
    padding: 32px;
    width: 100%;
    height: 95%;
    max-width: 900px;
    background-color: #161616;
    overflow: scroll;


}

.popup-inner .close-btn{
    position: absolute;
    top: 16px;
    right: 16px;
    border: none;
    font-size: 2rem;
    background: none;
    cursor: pointer;
    color: gray;
}

.popup-inner h3{
    font-size: 2rem;
    margin-bottom: 1rem;
}

.popup-inner p{
    margin-bottom: 1rem;
    color: black;
}

.popup-inner p i{
    margin-right: 1rem;
    color: black;
}

@media screen and (max-width: 1120px){
    .popup-inner .close-btn i{
        color: white;
        overflow: scroll;
    }
}