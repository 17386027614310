.tileContainer{
    width: 95%;
    box-shadow: 0px 0px 15px -5px;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    padding: 1.2rem 1.2rem 1rem none;
    background-color: #222222;
    max-width: 500px;

}

.tileContainer h2{
    color: rgb(23, 119, 31);
    margin-bottom: 0.5rem;
}

.tileImage-container img {
    width:100%;
    height:100%;
    object-fit:cover;
}

.tile-body{
    color: aliceblue;
    font-size: 1.2rem;
}