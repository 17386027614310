/* MobileHome container */
.mobile-home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Header image */
.header-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh; /* Set the height to 100vh for full-screen height */
  overflow: hidden; /* Hide any overflow beyond the sides */
}

.header-image img {
  width: auto;
  height: 100%; /* Set the height to 100% to fill the container vertically */
  object-fit: cover;
  object-position: center;/* Added to center the image horizontally */
}

.big-words {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 3rem; /* Customize the font size */
  color: white; /* Customize the text color */
  text-shadow: 2px 2px 4px black;
  z-index: 998;
}

.small-words {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem; /* Customize the font size */
  color: #fff; /* Customize the text color */
  text-shadow: 2px 2px 4px black;
  z-index: 998;
}

/* Additional styles for the rest of the mobile homepage content */

/* Additional styles for the desktop version */
.desktop-home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  justify-content: center;
  align-items: center;
  
}

.gradient{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 997;
  background-color: rgba(0, 0, 0, 0.6);
}

.desktop-home .header-image {

  justify-content: center;
  align-items: center;
  object-position: center;
  object-fit: fill; 
}

.desktop-home .foto2 {
  justify-content: center;
  align-items: center;
  width: auto; /* Updated to auto width */
  height: 100%; /* Updated to 100% height */
 overflow: hidden; /* Added to hide any overflow beyond the sides */

}
.desktop-home .foto2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh; /* Set the height to 100vh for full-screen height */
  overflow: hidden; /* Hide any overflow beyond the sides */
}

.desktop-home .foto2 img {
  width: auto;
  height: 100%; /* Set the height to 100% to fill the container vertically */
  object-fit: cover;
  object-position: center;
}