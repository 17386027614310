/* Navbar container */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: rgba(12, 12, 12, 0.4); /* Customize the background color */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
  
  /* Logo */
  .logo img {
    width: 30px; /* Adjust the logo size as needed */
  }
  
  /* Hamburger menu */
  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    cursor: pointer;
  }
  
  .line {
    width: 100%;
    height: 2px;
    background-color: white; /* Customize the line color */
    transition: all 0.3s ease;
  }
  
  .hamburger.active .line:nth-child(1) {
    transform: translateY(8.5px) rotate(45deg);
  }
  
  .hamburger.active .line:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger.active .line:nth-child(3) {
    transform: translateY(-9px) rotate(-45deg);
  }
  
  /* Sidebar */
  .sidebar {
    position: fixed;
    top: 0;
    left: -100%;
    width: 80%; /* Customize the sidebar width */
    height: 100vh;
    background-color: #212121; /* Customize the sidebar background color */
    padding: 20px;
    transition: all 0.3s ease;
    z-index: 998;
  }
  
  .sidebar.open {
    left: 0;
    padding: 0;
  }
  
  .sidebar a {
    display: block;
    padding-bottom: 10px;
    color: white; /* Customize the sidebar link color */
    text-decoration: none;
    transition: background-color 0.3s ease; /* Add transition for smooth effect */
    background-color: transparent;
    padding: 20px;
    border-bottom: 1px solid rgb(61, 61, 61);
    width: 100%;
  }
  
  .sidebar a:hover {
    background-color: darkgreen; /* Customize the hover background color */
    color: #fff; /* Customize the hover text color */
  }
  
  /* Media queries */
  @media screen and (min-width: 1200px) {
    .navbar {
      /* Styles for desktop version */
    }
  
    .hamburger,
    .sidebar {
      display: none;
    }
  }
  