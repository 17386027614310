


*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Karla', sans-serif;
}

.App{
  background-color: rgb(33, 33, 33);
}

.body{
  min-height: calc(100vh - 200px);
  overflow: hidden;
  overflow-y: hidden;
}

.pageWrapper{
  width: 70%;
  margin: auto;
  margin-top: 110px;
}

.row {
  display: flex;
}

.vertical-center{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.paginaTitel{
  text-align: center;
  height: 2rem;
  color: rgb(192, 192, 192);
  font-size: 3rem;
  letter-spacing: 3px;
  font-weight: 700;
  margin: 4rem auto;
}


/*HOME*/

.bannerText h1{
  text-align: center;
  z-index: 1000;
}


.fc .fc-list-day-cushion .fc-cell-shaded{
  background-color: yellow;
}

.fc .fc-list-sticky .fc-list-day > * {
  background: #212121;
}

.no-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.no-scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}